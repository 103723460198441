<script>
import Modal from '@maison/components/common/Modal.vue';
import modalMixin from '@phoenix/mixins/modalMixin';

export default {
  components: {
    Modal,
  },

  mixins: [modalMixin],

  props: {
    qrcode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <modal :id="id" :title="title" small>
    <template #content>
      <img :src="qrcode" class="align-center" :alt="alt" />
    </template>
  </modal>
</template>
